<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase text-nowrap">
            ตั้งค่าเงื่อนไขการจัดส่งต่างประเทศ
          </h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-button
              class="btn-cancel mx-2 btn-secondary"
              @click="importProduct()"
              >นำเข้าข้อมูล</b-button
            >
            <b-button
              class="btn-cancel mx-2 btn-secondary"
              @click="exportData()"
              >นำออกข้อมูล</b-button
            >
          </div>
        </b-col>
      </CRow>

      <b-container
        class="container-box bg-white px-4 py-4 mt-4"
        v-for="(itemlist, i) in form"
        v-bind:key="i"
      >
        <b-row>
          <b-col cols="6">
            <InputText
              textFloat="ชื่อขนส่ง"
              placeholder="ชื่อขนส่ง"
              type="text"
              v-model="itemlist.detail.name"
              :disabled="true"
            />
          </b-col>
          <b-col cols="6">
            <InputText
              textFloat="ประเภทเงื่อนไข"
              placeholder="ประเภทเงื่อนไข"
              type="text"
              v-model="itemlist.detail.shippingConditionName"
              :disabled="true"
            />
          </b-col>
        </b-row>
        <b-row
          class="no-gutters"
          v-for="(item, index) in itemlist.list"
          v-bind:key="index"
        >
          <InputText
            textFloat="ประเทศ"
            placeholder="ชื่อประเทศ"
            type="text"
            v-model="item.country"
            :disabled="true"
          />
          <b-table
            responsive
            class="text-center table-list"
            striped
            :fields="fields"
            :items="item.costRange"
            :busy="isBusy"
            show-empty
            empty-text="ไม่พบข้อมูล"
          >
            <template v-slot:cell(startRange)="data">
              <InputText
                placeholder="นำ้หนักเริ่มต้น (กิโลกรัม)"
                type="number"
                v-model="data.item.startRange"
                isRequired
                class="mt-3"
                :disabled="true"
              />
            </template>
            <template v-slot:cell(endRange)="data">
              <InputText
                placeholder="นำ้หนักสิ้นสุด (กิโลกรัม)"
                type="number"
                v-model="data.item.endRange"
                isRequired
                class="mt-3"
                :disabled="true"
              />
            </template>
            <template v-slot:cell(cost)="data">
              <InputText
                placeholder="ระบุค่าขนส่งที่ต้องการ"
                type="number"
                name="price"
                v-model="data.item.cost"
                isRequired
                class="mt-3"
                :disabled="true"
              />
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-row>
        <b-row class="px-3">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="itemlist.detail.enabled"
                  :disabled="true"
                >
                  <span class="ml-2 main-label">
                    {{ itemlist.detail.enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row class="px-3">
          <b-col class="d-flex justify-content-end align-items-center">
            <router-link :to="'/shipping-setting/abroad'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <!-- <b-button class="btn-main ml-3" @click="checkForm()"
                >บันทึก</b-button
              > -->
          </b-col>
        </b-row>
      </b-container>
    </div>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalImportFile
      ref="modalImportFile"
      path="Shipping/AboardShipping/Import"
      colorBtnConfirm="primary"
      :base64="true"
      @success="importSuccess"
    />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import ModalImportFile from "@/components/modal/ModalImportFile";
import axios from "axios";

export default {
  name: "SettingDetails",
  components: {
    InputText,
    ModalAlertError,
    ModalImportFile,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
    InputSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      fields: [
        // {
        //   key: "id",
        //   label: "",
        //   class: "w-25x",
        // },
        {
          key: "startRange",
          label: "น้ำหนักเริ่มต้น (กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "endRange",
          label: "น้ำหนักสิ้นสุด (กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "cost",
          label: "ค่าขนส่ง (บาท)",
          class: "w-100px text-nowrap",
        },
      ],
      isBusy: false,
      error: [],
      enabled: true,
      form: [],
      condition: [],
      filter: {
        PageNo: 1,
        PerPage: 10,
      },
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    importProduct: async function () {
      this.$refs.modalImportFile.show();
    },
    importSuccess: async function (data) {
      if (data.detail.length) {
        for (const e of data.detail) {
          this.form.push(e);
        }
        for (const element of this.form) {
          let index = this.condition.findIndex((e) => {
            return e.id === element.detail.shippingConditionId;
          });
          if (index != -1) {
            element.detail.shippingConditionName = this.condition[index].name;
          }
        }
      }
    },
    getList: async function () {
      this.isBusy = true;
      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Shipping/AboardShipping/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (res.result == 1) {
        this.form.push(res.detail);
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/shippingcondition`,
        null,
        this.$headers,
        this.filter
      );

      if (data.result == 1) {
        this.condition = data.detail;
      }
      this.isBusy = false;
    },
    exportData: async function () {
      this.$refs.modalLoading.show();
      await axios({
        url: `${this.$baseUrl}/api/Shipping/AboardShipping/Export`,
        method: "get",
        headers: this.$headers,
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `shipping.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs.modalLoading.hide();
    },
    // checkForm: async function () {
    //   this.$v.form.$touch();
    //   if (this.$v.form.$error) {
    //     this.$nextTick(() => {
    //       let target = document.querySelector(".text-error");
    //       if (target) {
    //         let domRect = document
    //           .querySelector(".text-error")
    //           .getBoundingClientRect();
    //         window.scrollTo(
    //           domRect.left + document.documentElement.scrollLeft,
    //           domRect.top + document.documentElement.scrollTop - 450
    //         );
    //       }
    //     });
    //     return;
    //   }
    //   this.$refs.modalLoading.show();
    //   let num = !this.enabled ? 0 : 1;
    //   await this.$callApi(
    //     "get",
    //     `${this.$baseUrl}/api//Shipping/AboardShipping/Enable/${num}`,
    //     null,
    //     this.$headers,
    //     null
    //   );

    //   let resData = await this.$callApi(
    //     "post",
    //     `${this.$baseUrl}/api//Shipping/AboardShipping/Save`,
    //     null,
    //     this.$headers,
    //     this.form
    //   );

    //   this.$refs.modalLoading.hide();
    //   if (resData.result == 1) {
    //     this.modalMessage = "สำเร็จ";
    //     this.$refs.modalAlert.show();
    //     setTimeout(() => {
    //       this.$refs.modalAlert.hide();
    //     }, 2000);
    //   } else {
    //     this.modalMessage = resData.message;
    //     this.$refs.modalAlertError.show();
    //     setTimeout(() => {
    //       this.$refs.modalAlertError.hide();
    //     }, 2000);
    //   }
    // },
  },
};
</script>

<style scoped>
::v-deep .table td {
  vertical-align: baseline !important;
}

::v-deep .table-responsive {
  overflow: auto;
}

.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error,
.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

label {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  width: 100%;
  font-weight: bold;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .dropdown-menu-right {
  width: 200px;
  padding: 5px;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

.category-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  padding: 5px 10px;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
  border: 1px solid #bcbcbc !important;
}

.category-popover {
  position: absolute;
  z-index: 100;
  border: 1px solid #dcdcdc;
  overflow-y: auto;
  right: 0;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12),
    0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}

@media (max-width: 600px) {
  .category-main-box {
    width: 100%;
    margin-top: 10px;
  }

  .category-popover {
    right: 15px;
  }
}
</style>
